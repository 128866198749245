import { route } from "preact-router";

export const useSurveyIds = (responseId: string) => {
  const [surveyId, responderId] = responseId.split("-");

  if (!surveyId || !responderId) {
    route("/bad-survey-id/");
    return;
  }

  return { surveyId, responderId };
};
