import { h, Fragment, FunctionalComponent } from "preact";
import { CardContent, UserAnswer, AnswerValue } from "./Question.style";
import Scale from "../Scale/Scale";
import { QuestionProps } from "./Question";
import SvgIcon from "../Svg/SvgIcon";
import { RootState, useSurvey } from "../../state/root";
import { translate } from "../../utils/language";

const getLanguage = (root: RootState): Language => root.state.language;

const answerLabelTranslation = {
  fi: "Vastauksesi",
  en: "Your answer",
  se: "Ditt svar"
};

const RangeQuestion: FunctionalComponent<QuestionProps> = ({
  question,
  isAnswered,
  answer,
  handleFocusInteraction
}: QuestionProps) => {
  const lang = useSurvey(getLanguage);
  const scale = question.scale;
  const answerLabel =
    answer &&
    translate(
      scale[Math.min(Math.floor(Number(answer) - 1), scale.length - 1)].label,
      lang
    );
  return (
    <Fragment>
      <CardContent>
        <Scale
          isAnswered={isAnswered}
          question={question}
          onInteraction={handleFocusInteraction}
        />
      </CardContent>
      <UserAnswer checked={isAnswered}>
        <AnswerValue>
          <SvgIcon option="check" /> {answerLabelTranslation[lang]}: {answer} -{" "}
          {answerLabel}
        </AnswerValue>
      </UserAnswer>
    </Fragment>
  );
};

export default RangeQuestion;
