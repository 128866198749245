import { FunctionalComponent, h } from "preact";
import { useMemo } from "preact/hooks";
import { SurveyQuestion } from "../../models/Survey";
import {
  CardQuestion,
  CardHeader,
  QuestionText,
  InfoText
} from "./Question.style";
import { useSurvey, RootState, Answer } from "../../state/root";
import { translate } from "../../utils/language";
import { useSurveyForm } from "../../state/navigation";
import RangeQuestion from "./RangeQuestion";
import FreeTextQuestion from "./FreeText";

// TODO: replace hard-coded id with general solution if we need to support more of these
const areResultsOnlyForManager = (questionId: string): boolean =>
  ["4baea77a-a302-4bdf-83ad-17c996abc729"].includes(questionId);

const managerOnlyInfoText = {
  fi: "Vain tiimin esihenkilö näkee tämän kysymyksen vastaukset.",
  en: "The results of this question are only available for team manager.",
  se: "Resultaten av denna fråga är endast tillgängliga för förman."
};

const getLanguage = (root: RootState): Language => root.state.language;

export interface QuestionProps {
  question: SurveyQuestion;
  isAnswered: boolean;
  answer: Answer;
  handleFocusInteraction: (id: string, step: number) => void;
}

const Question: FunctionalComponent<QuestionProps> = (props: QuestionProps) => {
  const { question } = props;
  const lang = useSurvey(getLanguage);
  const questionLabel = useMemo(() => translate(question.question, lang), [
    lang,
    question.question
  ]);
  const { activeQuestion } = useSurveyForm();
  const isSelectedQuestion = activeQuestion && activeQuestion === question.id;
  const isActiveQuestion = !activeQuestion || isSelectedQuestion;

  return (
    <CardQuestion active={isActiveQuestion}>
      <CardHeader>
        <QuestionText active={isSelectedQuestion}>
          <mark>{questionLabel}</mark>
        </QuestionText>
      </CardHeader>
      {question.type === "select" ? (
        <RangeQuestion {...props} />
      ) : (
        <FreeTextQuestion {...props} />
      )}
      {areResultsOnlyForManager(question.id) && (
        <InfoText>{managerOnlyInfoText[lang]}</InfoText>
      )}
    </CardQuestion>
  );
};

export default Question;
