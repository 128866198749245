import { createRef, RefObject } from "preact";
import { useEffect } from "preact/hooks";
import { useSurveyForm } from "../state/navigation";

/**
 * Hook that scrolls view to given question when that question becomes active.
 * The hook returns ref that is used as scroll target.
 */
export const useScrollToQuestion = (questionId: string): RefObject<unknown> => {
  const { activeQuestion } = useSurveyForm();
  const inputRef = createRef();

  useEffect(() => {
    if (inputRef.current && activeQuestion === questionId) {
      inputRef.current.focus();
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [inputRef, activeQuestion, questionId]);

  return inputRef;
};
