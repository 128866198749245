import { h } from "preact";
import { SurveySection } from "../../models/Survey";
import { Name } from "./style";
import { TextBlock, Section } from "../../core/style";
import MultiQuestionCard from "../MultiQuestionCard/MultiQuestionCard";
import { useSurvey, RootState } from "../../state/root";
import { translate } from "../../utils/language";

interface Props {
  section: SurveySection;
  prev: SurveySection | null;
  next: SurveySection | null;
}

const getLanguage = (root: RootState) => root.state.language;

const Component = ({ section, prev, next }: Props) => {
  const lang = useSurvey(getLanguage);
  const intro = translate(section.introduction, lang);

  const getNextQuestionId = (setIdx: number) => {
    if (setIdx === section.questionSet.length - 1) {
      // TODO: Return next section
      if (!next) {
        return "";
      }
      return next.questionSet[0].questions[0].id;
    }

    return section.questionSet[setIdx + 1].questions[0].id;
  };

  const getPreviousQuestionId = (setIdx: number) => {
    if (setIdx === 0) {
      if (!prev) {
        return "";
      }
      const questionSet = prev.questionSet;
      return questionSet[questionSet.length - 1].questions[
        questionSet[questionSet.length - 1].questions.length - 1
      ].id;
    }
    const previousQuestionSet = section.questionSet[setIdx - 1];
    const { questions } = previousQuestionSet;
    return questions[questions.length - 1].id;
  };

  return (
    <Section key={translate(section.name, lang)}>
      <Name>{translate(section.name, lang)}</Name>
      {intro && <TextBlock style={{ marginTop: "1rem" }}>{intro}</TextBlock>}
      {section.questionSet.map((item, idx) => (
        <MultiQuestionCard
          key={`question-card-${idx}`}
          group={item}
          previousQuestionId={getPreviousQuestionId(idx)}
          nextQuestionId={getNextQuestionId(idx)}
        />
      ))}
    </Section>
  );
};

export default Component;
