import { FunctionalComponent, h } from "preact";
import { Answer, useSurvey } from "../../state/root";
import styled from "styled-components";
import { useSurveyForm } from "../../state/navigation";
import { useState } from "react";
import { useCallback } from "react";
import { SurveyQuestion } from "src/models/Survey";
import { useScrollToQuestion } from "../../hooks/useScrollToQuestion";

const INPUT_MAX_LENGTH = 1000;

const lengthRemaining = (value: string): number =>
  INPUT_MAX_LENGTH - value.length;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TextArea = styled.textarea`
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius: 8px;
  width: 100%;
  min-height: 120px;
  padding: 8px;
`;

const AllowedInputLength = styled.p`
  margin-top: 0.25rem;
  padding-right: 0.5rem;
  font-size: 0.8rem;
  color: ${({ value }: { value: string }): string =>
    lengthRemaining(value) === 0 ? "red" : "unset"};
`;

interface Props {
  question: SurveyQuestion;
  answer: Answer;
  handleFocusInteraction: (id: string, step: number) => void;
}

const FreeTextQuestion: FunctionalComponent<Props> = ({
  question,
  answer,
  handleFocusInteraction
}: Props) => {
  const [value, setValue] = useState(String(answer ?? ""));
  const { setActiveQuestion } = useSurveyForm();
  const { setAnswer } = useSurvey();
  const inputRef = useScrollToQuestion(question.id);

  const onInputChange = useCallback(
    ({ target }: { target: HTMLTextAreaElement }): void =>
      setValue(target.value),
    [setValue]
  );

  const onBlur = useCallback((): void => {
    setAnswer(question.id, value);
    handleFocusInteraction(question.id, 1);
  }, [value, question.id, setAnswer, handleFocusInteraction]);

  return (
    <Container>
      <TextArea
        onFocus={(): void => setActiveQuestion(question.id)}
        onBlur={onBlur}
        value={value}
        onChange={onInputChange}
        ref={inputRef}
        maxLength={INPUT_MAX_LENGTH}
      />
      <AllowedInputLength value={value}>
        {lengthRemaining(value)}
      </AllowedInputLength>
    </Container>
  );
};

export default FreeTextQuestion;
