import styled from "styled-components";

export const Title = styled.h1`
  font-weight: 600;
  padding: 1rem;
  font-size: 2rem;
  font-family: "Red Hat Bold", sans-serif;

  @media (max-width: 700px), (max-device-width: 700px) {
    font-size: 1.1rem;
  }
`;

export const LanguageSelector = styled.div`
  display: flex;
  flex-direction: row;
  > a {
    color: #333;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const TextBlock = styled.p`
  padding: 0.5rem;
  line-height: 1.5;
  font-size: 1.3rem;
  font-family: "Zilla Slab Medium", serif;
  color: var(--color-gray);

  a {
    color: darkmagenta;
  }

  @media (max-width: 700px), (max-device-width: 700px) {
    font-size: 1.2rem;
  }
`;

export const Application = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
  background-image: linear-gradient(
    45deg,
    hsl(6deg 38% 95%) 0%,
    hsl(6deg 37% 96%) 10%,
    hsl(6deg 34% 96%) 20%,
    hsl(6deg 29% 97%) 30%,
    hsl(6deg 20% 98%) 40%,
    hsl(0deg 0% 99%) 50%,
    hsl(59deg 39% 98%) 60%,
    hsl(59deg 63% 98%) 70%,
    hsl(60deg 80% 98%) 80%,
    hsl(60deg 91% 97%) 90%,
    hsl(60deg 100% 97%) 100%
  );
  min-height: 100%;
  flex: 1;
  width: 100%;

  > section {
    width: 100%;
    max-width: 800px;
  }

  * {
    box-sizing: border-box;
  }
`;

export const Section = styled.section`
  padding: 0rem 3rem;
  margin-top: 1rem;

  :first-child {
    margin-top: 0rem;
  }

  @media (max-width: 700px), (max-device-width: 700px) {
    padding: 0 1rem;
  }
`;
