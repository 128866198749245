import { h } from "preact";
import styled from "styled-components";
import { useMemo, useState } from "preact/hooks";
import {
  getRequiredQuestionsCount,
  useSurvey,
  getQuestionsWithAnswers,
  RootState,
  isRequiredQuestion,
  getRequiredAnswerCount
} from "../../state/root";
import { useSurveyForm } from "../../state/navigation";
import SurveySubmit from "../SurveySubmit/SurveySubmit";
import { AnsweredSurvey } from "../../models/Survey";

const ProgressContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 7;
  justify-content: center;
`;

const Value = styled.h3`
  font-family: Red Hat Bold;
  letter-spacing: 0.1rem;
  padding: 0.25rem;
  position: relative;
  z-index: 3;
  font-size: 1.3rem;
`;

const BarContainer = styled.span`
  width: 100%;
  background: whitesmoke;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 3rem;
  position: relative;

  &:hover {
    background: #fff;
  }
`;

const ProgressBar = styled.span`
  background: var(--color-yellow-dark);
  width: ${(props: { value: number }) => props.value}%;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  display: block;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const QuestionContainer = styled.div`
  background: var(--color-background);
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;
const QuestionBubble = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0.2rem;
  background: #fff;
  cursor: pointer;
`;

const Legend = styled.span`
  font-size: 0.8rem;
  position: absolute;
  right: 1rem;
  top: auto;
  bottom: auto;
`;

const QuestionList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: flex-end;
`;

const legend = {
  fi: "Tarvitsetko apua?",
  en: "Need help?",
  se: "Behöver du hjälp?"
};

const closingLegend = {
  fi: "Piilota klikkaamalla",
  en: "Hide with click",
  se: "Göm med klick"
};

const explanation = {
  fi:
    "Yläpalkissa näet prosenttiluvun vastatuista kysymyksistä. Alhaalla näet yksittäiset kysymykset listana. Painamalla kysymystä, pääset sen luokse. Vastaamattomat kysymykset on listattu valkoisina.",
  en:
    "The top bar shows the percentage of answered questions. Below you see the individual questions in a list. Clicking a question takes you to it. Unanswered questions are shown in white.",
  se:
    "Överst på skärmen visas procentandelen av besvarade frågor. Nedanför finns frågor i en lista. Klicka på en fråga för att gå till den. Obesvarade frågor visas i vit."
};

const getLanguage = (root: RootState) => root.state.language;

interface Props {
  postAnswers: (
    surveyId: string,
    responderId: string,
    answeredSurvey: AnsweredSurvey
  ) => Promise<unknown>;
}

const SurveyProgress = (props: Props) => {
  const { unfocus, setActiveQuestion } = useSurveyForm();
  const [displayProgress, setDisplayProgress] = useState<boolean>(false);
  const { state } = useSurvey();
  const lang = useSurvey(getLanguage);
  const questions = useSurvey(getQuestionsWithAnswers);
  const answerCount = getRequiredAnswerCount(state);
  const { survey } = state;
  const totalRequiredCount = useMemo(
    () => (survey ? getRequiredQuestionsCount(survey) : 1),
    [survey]
  );

  const answeredPercentage = useMemo(
    () => Math.min((answerCount / totalRequiredCount) * 100, 100),
    [answerCount, totalRequiredCount]
  );

  const handleOpenContainer = () => {
    unfocus();
    setDisplayProgress(!displayProgress);
  };

  const handleClick = (id: string) => {
    setActiveQuestion(id);
  };

  return (
    <ProgressContainer
      style={{
        bottom: 0
      }}
    >
      <BarContainer role="button" onClick={handleOpenContainer}>
        <ProgressBar value={answeredPercentage} />
        <Value>
          {Math.floor(answeredPercentage)}
          <span style={{ fontSize: "1rem" }}>%</span>
        </Value>
        <Legend>{displayProgress ? closingLegend[lang] : legend[lang]}</Legend>
      </BarContainer>
      <QuestionContainer style={{ display: displayProgress ? "flex" : "none" }}>
        <p style={{ padding: "1rem 0rem" }}>{explanation[lang]}</p>
        <QuestionList>
          {questions.map((question, idx) => (
            <QuestionBubble
              key={`question-bubble-${question.id}`}
              onClick={() => handleClick(question.id)}
              style={{
                background:
                  question.answer || !isRequiredQuestion(question)
                    ? "var(--color-yellow-dark)"
                    : "#fff"
              }}
            >
              {idx}
            </QuestionBubble>
          ))}
        </QuestionList>
        <SubmitContainer>
          <SurveySubmit {...props} />
        </SubmitContainer>
      </QuestionContainer>
    </ProgressContainer>
  );
};

export default SurveyProgress;
