export const translate = (item: Translation, language: Language = "en") => {
  if (typeof item === "string") {
    return item;
  }
  if (!item[language] || item[language] == "") {
    if (!item.en) {
      return "Missing translation";
    }
    return item.en;
  }
  return item[language];
};
