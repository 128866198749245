export enum HttpRequestStatus {
  "null",
  "loading",
  "error",
  "done"
}

export class HttpError extends Error {
  public statusCode: number;
  public description: string;
  public title: string;
  public code?: string;
  constructor(
    statusCode: number,
    title: string,
    description: string,
    code?: string
  ) {
    super();
    this.statusCode = statusCode;
    this.title = title;
    this.description = description;
    this.code = code;
  }
}
