import create from "zustand";

interface SurveyFormState {
  activeQuestion: string | null;
  setActiveQuestion: (id: string) => void;
  unfocus: () => void;
}

export const useSurveyForm = create<SurveyFormState>((set, get) => ({
  activeQuestion: null,
  unfocus: () => set(p => ({ ...p, activeQuestion: null })),
  setActiveQuestion: (id: string) =>
    set(p => {
      return { ...p, activeQuestion: id, questionFocused: true };
    })
}));

export const isFocusMode = (state: SurveyFormState) => {
  return Boolean(state.activeQuestion);
};
