import { h, Fragment } from "preact";
import { useEffect, useState, useMemo } from "preact/hooks";
import { HttpRequestStatus, HttpError } from "../../models/Http";
import { isComplete, isSent } from "../../utils/validation";
import { getSurveyById, postAnsweredSurvey } from "../../core/api";
import { useSurveyIds } from "../../hooks/useSurveyIds";
import { useSurvey } from "../../state/root";
import SurveyComponent from "./Survey";
import ErrorView from "../../components/Error/Error";

interface Props {
  language: Language;
  id: string;
}

function SurveyPage(props: Props) {
  const { language, id } = props;
  const ids = useSurveyIds(id);

  const [surveyFetchStatus, setFetchState] = useState<HttpRequestStatus>(
    HttpRequestStatus.null
  );

  const [fetchError, setFetchError] = useState<HttpError | null>(null);

  const { updateSlice, state } = useSurvey();
  const survey = useMemo(() => state.survey, [state.survey]);

  useEffect(() => {
    updateSlice({ language });
  }, [language]);

  useEffect(() => {
    if (isSent(surveyFetchStatus) || !ids) {
      return;
    }
    setFetchState(HttpRequestStatus.loading);
    getSurveyById(ids.surveyId, ids.responderId)
      .then(response => {
        updateSlice({ survey: response.survey });
        setFetchState(HttpRequestStatus.done);
      })
      .catch(e => {
        console.log(e);
        setFetchState(HttpRequestStatus.error);
        setFetchError(e);
      });
  }, [surveyFetchStatus, ids]);

  return (
    <Fragment>
      {isComplete(surveyFetchStatus) && survey && (
        <SurveyComponent
          survey={survey}
          responseId={id}
          postAnswers={postAnsweredSurvey}
        />
      )}
      {fetchError && <ErrorView error={fetchError} />}
    </Fragment>
  );
}

export default SurveyPage;
