import { h, Fragment } from "preact";
import { HttpError } from "../../models/Http";
import { TextBlock, Title } from "../../core/style";
import { getLanguage, useSurvey } from "../../state/root";
import { translate } from "../../utils/language";

interface Props {
  error: HttpError;
}

const ERROR_MESSAGES: Record<string, Translation> = {
  "4805b46d-92eb-43f9-b941-7f787f31da57": {
    en: "You have already answered this survey.",
    fi: "Olet ja vastannut tähän kyselyyn.",
    se: "Du har redan svarat på den här enkäten."
  },
  "115a8280-3e98-4b0d-9ce7-7365fe5e735d": {
    en: "We did no find a survey with given information.",
    fi: "Emme löytäneet hakemaasi kyselyä.",
    se: "Vi hittade ingen undersökning med given information."
  },
  "d9e99bef-7e87-4e5d-9770-ed570515086c": {
    en: "Survey is already closed.",
    fi: "Kysely on jo sulkeutunut.",
    se: "Undersökningen är redan stängd."
  },
  "a9047e33-5f9e-4c0c-b593-5776d8945cb9": {
    en: "We did no find a survey with given information.",
    fi: "Emme löytäneet hakemaasi kyselyä.",
    se: "Vi hittade ingen undersökning med given information."
  },
  title: {
    en: "Something went wrong while loading the survey.",
    fi: "Kyselyä ladattaessa tapahtui virhe.",
    se: "Något gick fel när undersökningen laddades."
  }
};

function ErrorView({ error }: Props) {
  const lang = useSurvey(state => state.state.language);
  return (
    <Fragment>
      <Title>{translate(ERROR_MESSAGES.title, lang)}</Title>
      <TextBlock>
        {error.code ? translate(ERROR_MESSAGES[error.code] ?? "", lang) : null}
      </TextBlock>
      <img
        src="/assets/images/bugs.svg"
        alt="Engineer driving bugs away from display"
      />
    </Fragment>
  );
}

export default ErrorView;
