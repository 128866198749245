import { h } from "preact";
import styled from "styled-components";
import { useMemo } from "preact/hooks";
import {
  getRequiredAnswerCount,
  getRequiredQuestionsCount,
  useSurvey
} from "../../state/root";
import { AnsweredSurvey } from "../../models/Survey";
import { route } from "preact-router";

const SubmitButton = styled.button`
  border: 2px solid var(--color-gray);
  color: var(--color-background);
  box-shadow: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-family: Red Hat Bold, sans-serif;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  background: var(--color-gray);

  &:hover {
    cursor: pointer;
    color: var(--color-yellow-light);
    background-color: var(--color-black);
  }
`;

const submitFnLabel = {
  fi: "Lähetä vastauksesi",
  en: "Submit answers",
  se: "Skicka in svar"
};

interface Props {
  postAnswers: (
    surveyId: string,
    responderId: string,
    answeredSurvey: AnsweredSurvey
  ) => Promise<unknown>;
}

const mapToRecord = (map: Map<string, any>) => {
  return Array.from(map).reduce((obj, [key, value]: [string, any]) => {
    obj[key] = value;
    return obj;
  }, {} as Record<string, any>);
};

const SurveySubmit = (props: Props) => {
  const { state } = useSurvey();
  const { survey, language } = state;
  const answerCount = getRequiredAnswerCount(state);
  const requiredQuestionsCount = useMemo(
    () => (survey ? getRequiredQuestionsCount(survey) : 1),
    [survey]
  );
  const isAnswerable = answerCount >= requiredQuestionsCount;

  const onSubmit = async () => {
    if (!survey) {
      return;
    }
    const answeredSurvey: AnsweredSurvey = {
      ...survey,
      answers: mapToRecord(state.answers)
    };
    try {
      await props.postAnswers(
        survey.surveyId,
        survey.responderId,
        answeredSurvey
      );
      return route("/success");
    } catch {
      return route("/oh-dmn");
    }
  };

  return (
    <SubmitButton onClick={onSubmit} disabled={!isAnswerable}>
      {submitFnLabel[language]}
    </SubmitButton>
  );
};

export default SurveySubmit;
