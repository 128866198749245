import { get, post } from "./axiosClient";
import { Survey, AnsweredSurvey } from "../models/Survey";
import { apiRootUrl } from "../config";
import { getOptionalQuestions } from "../state/root";

export const getSurveyById = (surveyId: string, responderId: string) =>
  get<{ survey: Survey }>(
    `${apiRootUrl}/v2/survey?sid=${surveyId}&rid=${responderId}`
  );

export const getSelfAssessmentById = (surveyId: string, responderId: string) =>
  get<{ survey: Survey }>(
    `${apiRootUrl}/v2/survey?sid=${surveyId}&rid=${responderId}&self=true`
  );

export const postAnsweredSurvey = (
  surveyId: string,
  responderId: string,
  survey: AnsweredSurvey
) => {
  const optionalQuestions = getOptionalQuestions(survey.content);
  const defaultAnswers = optionalQuestions
    .filter(question => !survey.answers[question.id])
    .reduce((prev, question) => ({ ...prev, [question.id]: "" }), {});
  const surveyData = {
    surveyId: surveyId,
    responderId: responderId,
    answers: { ...defaultAnswers, ...survey.answers }
  };
  return post(`${apiRootUrl}/v2/survey/`, surveyData);
};

export const postSelfAssessment = (
  surveyId: string,
  responderId: string,
  survey: AnsweredSurvey
) => {
  const surveyData = {
    surveyId: surveyId,
    responderId: responderId,
    answers: survey.answers
  };
  return post(`${apiRootUrl}/v2/survey/`, surveyData);
};
