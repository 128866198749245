import axios, { AxiosResponse, Method, AxiosError } from "axios";
import { HttpError } from "../models/Http";

function getConfig(method: Method) {
  return {
    method,
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=utf-8"
    }
  };
}

const handleError = (err: AxiosError) => {
  const { response, request } = err;
  if (response) {
    const status = response.status || 500;
    const statusText = response.statusText || "Unknown error";
    const description = response.data.description || "Something went wrong.";
    throw new HttpError(status, statusText, description, response.data.code);
  }
  if (request) {
    const message = request.data.description;
    throw new Error(message);
  }
  throw err;
};

export async function get<T>(url: string) {
  try {
    const response: AxiosResponse<T> = await axios.get(url, getConfig("get"));
    return response.data;
  } catch (err) {
    return handleError(err);
  }
}

export async function axiosDelete(url: string) {
  try {
    const response = await axios.delete(url, getConfig("delete"));
    return response;
  } catch (err) {
    return handleError(err);
  }
}

export async function post<T>(url: string, body: any) {
  try {
    const response = await axios.post<T>(url, body, getConfig("post"));
    return response.data;
  } catch (err) {
    return handleError(err);
  }
}
