import { h, FunctionComponent } from "preact";
import { useCallback } from "preact/hooks";
import { SurveyQuestionSet, SurveyQuestion } from "../../models/Survey";
import { TextBlock } from "../../core/style";
import { SetLabel, QuestionContainer } from "./MultiQuestionCard.style";
import { useSurvey, RootState } from "../../state/root";
import { translate } from "../../utils/language";
import { useSurveyForm } from "../../state/navigation";
import styled, { AnyStyledComponent } from "styled-components";
import Question from "../Question/Question";

export const GroupContainer: AnyStyledComponent = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: ${(props: any) => (props.active ? 6 : 1)};
  border-radius: 0.5rem;

  &:hover {
    opacity: ${(props: any) => (props.active ? 1 : 0.8)};
  }
`;

interface Props {
  group: SurveyQuestionSet;
  nextQuestionId: string;
  previousQuestionId: string;
}

const getLanguage = (root: RootState) => root.state.language;
const getAnswers = (root: RootState) => root.state.answers;

const MultiQuestionCard: FunctionComponent<Props> = ({
  group,
  nextQuestionId,
  previousQuestionId
}) => {
  const lang = useSurvey(getLanguage);
  const answers = useSurvey(getAnswers);
  const { questions } = group;

  const { activeQuestion, setActiveQuestion } = useSurveyForm();
  const focusMode = Boolean(activeQuestion);

  const questionIds = questions.map(q => q.id);
  const unblurGroup =
    (!!focusMode && questionIds.includes(activeQuestion!)) || !focusMode;

  const handleSelectNext = useCallback(
    (id: string, step: number) => {
      if (step === 0) {
        return;
      }

      const nextIdx = questions.findIndex(q => q.id === id) + step;

      const nextQuestionToSelect: string =
        nextIdx > questions.length - 1
          ? nextQuestionId
          : nextIdx < 0
          ? previousQuestionId
          : questions[nextIdx].id;

      return setActiveQuestion(nextQuestionToSelect);
    },
    [questions, setActiveQuestion]
  );

  const isAnswered = useCallback(
    (question: SurveyQuestion) => answers.has(question.id),
    []
  );

  return (
    <GroupContainer active={unblurGroup}>
      <SetLabel>{translate(group.name, lang)}</SetLabel>
      <TextBlock>{translate(group.introduction, lang)}</TextBlock>
      <QuestionContainer>
        {questions.map(question => (
          <Question
            isAnswered={isAnswered(question)}
            key={question.id}
            question={question}
            answer={answers.get(question.id) ?? null}
            handleFocusInteraction={handleSelectNext}
          />
        ))}
      </QuestionContainer>
    </GroupContainer>
  );
};

export default MultiQuestionCard;
