import { FunctionalComponent, h } from "preact";
import styled from "styled-components";
import { TextBlock } from "../../core/style";

const Container = styled.div`
  flex: 1:
  display: flex;
  text-align: center;
`;

const Title = styled.h1`
  font-weight: 600;
  padding: 1rem;
  font-size: 2rem;
`;

const Success: FunctionalComponent = () => {
  return (
    <Container>
      <Title>Thanks for your answer!</Title>
      <img
        src="/assets/images/winners.svg"
        alt="People holding trophies after filling surveys"
      />
      <TextBlock>
        We'll get back to you with youer results once there is a sufficient
        number of answers.
      </TextBlock>
      <TextBlock>
        <a href="https://app.thribune.com">
          Get more accustomed to Thribune while you wait!
        </a>
      </TextBlock>
    </Container>
  );
};

export default Success;
