import { h } from "preact";
import { TextBlock } from "../../core/style";
import SvgIcon from "../Svg/SvgIcon";
import { Button } from "../SurveyIntro/SurveyIntro.style";
import {
  ButtonContainer,
  Content,
  ExampleIcon,
  FlexedText,
  Header,
  Image,
  ImageContainer,
  Modal,
  Overlay
} from "./style";
import { useState } from "preact/hooks";

const SURVEY_TUTORIAL_SHOWN_KEY = "blb-survey-tutorial-shown";

function TutorialPopup() {
  const [alreadyShown, setAlreadyShown] = useState(
    localStorage.getItem(SURVEY_TUTORIAL_SHOWN_KEY) !== null
  );
  if (alreadyShown) {
    return null;
  }

  const closePopup = () => {
    localStorage.setItem(SURVEY_TUTORIAL_SHOWN_KEY, "true");
    setAlreadyShown(true);
  };

  return (
    <Overlay>
      <Modal>
        <Header>Hello there!</Header>
        <Content>
          <ImageContainer>
            <Image src="/assets/images/tutorial.svg" alt="" />
          </ImageContainer>
          <div>
            <TextBlock>
              Looks like this is the first time you are taking a Thribune
              survey. Here are a few tip for you to help you get started:
            </TextBlock>
            <TextBlock>
              Each survey contains number of questions that you can answer from
              scale of 1 to 5. You can set your answer by pressing one of the
              smily-icons or by dragging the slider.
            </TextBlock>

            <FlexedText>
              The smily-icons are arranged from
              <ExampleIcon>
                <SvgIcon option="worst" />
              </ExampleIcon>
              <span>(1) to</span>
              <ExampleIcon>
                <SvgIcon option="best" />
              </ExampleIcon>
              <span>(5)</span>
            </FlexedText>
          </div>
        </Content>
        <ButtonContainer>
          <Button onClick={closePopup}>Sounds good!</Button>
        </ButtonContainer>
      </Modal>
    </Overlay>
  );
}

export default TutorialPopup;
