import {
  AnsweredSurvey,
  Survey,
  SurveyContent,
  SurveyQuestion,
  SurveySection
} from "../models/Survey";
import { flatMap } from "../utils/data";
import create, { SetState } from "zustand";

export type Answer = number | string | null;

export interface State {
  answers: Map<string, Answer>;
  responderId: Maybe<string>;
  surveyId: Maybe<string>;
  survey: Maybe<Survey>;
  language: Language;
}

type UpdateSlice = (block: Partial<State>) => void;

interface Actions {
  updateSlice: UpdateSlice;
  setAnswer: (id: string, value: Answer) => void;
}

const initialState: State = {
  answers: new Map<string, Answer>(),
  responderId: null,
  surveyId: null,
  survey: null,
  language: "en"
};

export interface RootState extends Actions {
  state: State;
}

export const useSurvey = create<RootState>((set, get) => ({
  state: initialState,
  updateSlice: (partial: Partial<State>) =>
    set(p => ({ ...p, state: { ...p.state, ...partial } })),
  setAnswer: (id: string, value: Answer) => {
    return set((prev: RootState) => ({
      ...prev,
      answers: prev.state.answers.set(id, value)
    }));
  }
}));

export const getLanguage = (state: State) => {
  return state.language;
};

export const setAnswer = (set: SetState<RootState>) => (
  id: string,
  value: number
) => {
  return set((prev: RootState) => ({
    ...prev,
    state: {
      ...prev.state,
      answers: prev.state.answers.set(id, value)
    }
  }));
};

export const isRequiredQuestion = (question: SurveyQuestion): boolean =>
  question.type !== "freetext";

export const getRequiredQuestionIds = (survey: Survey): string[] => {
  const sections = survey.content.sections;
  const questions = sections
    .reduce((acc: string[][], next) => {
      const sets = next.questionSet.map(s =>
        s.questions.filter(isRequiredQuestion).map(q => q.id)
      );
      return acc.concat(sets);
    }, [])
    .reduce((acc, next) => [...acc, ...next], []);
  return questions;
};

export const getRequiredAnswerCount = (state: State) => {
  const { survey, answers } = state;
  if (!survey) {
    return 0;
  }
  const requiredIds = getRequiredQuestionIds(survey);
  return Array.from(answers.keys()).filter(id => requiredIds.includes(id))
    .length;
};

export const getRequiredQuestionsCount = (survey: Survey): number =>
  getRequiredQuestionIds(survey).length;

export const getOptionalQuestions = (
  content: SurveyContent
): SurveyQuestion[] =>
  content.sections.reduce((prev: SurveyQuestion[], curr: SurveySection) => {
    const questions = flatMap(
      curr.questionSet.map(set =>
        set.questions.filter(q => !isRequiredQuestion(q))
      )
    );
    return prev.concat(questions);
  }, []);

export const getFirstQuestionId = (survey: Maybe<Survey>): string => {
  if (!survey) {
    throw new Error("Survey is not defined");
  }

  const { content } = survey;

  if (!content) {
    throw new Error("Survey content is not defined");
  }
  const sets = flatMap(content.sections.map(s => s.questionSet));
  const questions = flatMap(sets.map(s => s.questions));
  return questions[0].id;
};

export const getQuestionsWithAnswers = ({ state }: RootState) => {
  if (!state.survey) {
    return [];
  }

  const { content } = state.survey;
  const questions = content.sections
    .map(s => s.questionSet)
    .reduce((acc: SurveyQuestion[], next) => {
      return acc.concat(
        next.reduce((a: SurveyQuestion[], s) => a.concat(s.questions), [])
      );
    }, [])
    .map(q => ({ ...q, answer: state.answers.get(q.id) }));
  return questions;
};
