import { h, Fragment } from "preact";
import { useEffect, useState, useMemo } from "preact/hooks";
import { HttpRequestStatus, HttpError } from "../../models/Http";
import SurveyIntro from "../../components/SurveyIntro/SurveyIntro";
import { isComplete, isSent } from "../../utils/validation";
import SurveyContent from "../../components/Sections/Sections";
import SurveyProgress from "../../components/SurveyProgress/SurveyProgress";
import { useSurvey } from "../../state/root";
import { getSelfAssessmentById, postSelfAssessment } from "../../core/api";
import { useSurveyIds } from "../../hooks/useSurveyIds";
import { LanguageSelector } from "../../core/style";
import SurveySubmit from "../../components/SurveySubmit/SurveySubmit";
import { Link } from "preact-router";
import styled from "styled-components";
import TutorialPopup from "../../components/TutorialPopup/TutorialPopup";
import ErrorView from "../../components/Error/Error";

interface Props {
  language: Language;
  id: string;
}

const SubmitContainer = styled.div`
  margin: 4rem 0;
`;

function SelfAssessment(props: Props) {
  const { language, id } = props;
  const ids = useSurveyIds(id);
  const [surveyStarted, setSurveyStarted] = useState(false);

  const [surveyFetchStatus, setFetchState] = useState<HttpRequestStatus>(
    HttpRequestStatus.null
  );

  const [fetchError, setFetchError] = useState<HttpError | null>(null);

  const { updateSlice, state } = useSurvey();
  const survey = useMemo(() => state.survey, [state.survey]);

  useEffect(() => {
    updateSlice({ language });
  }, [language]);

  useEffect(() => {
    if (isSent(surveyFetchStatus) || !ids) {
      return;
    }
    setFetchState(HttpRequestStatus.loading);
    getSelfAssessmentById(ids.surveyId, ids.responderId)
      .then(response => {
        updateSlice({ survey: response.survey });
        setFetchState(HttpRequestStatus.done);
      })
      .catch(e => {
        console.log(e);
        setFetchState(HttpRequestStatus.error);
        setFetchError(e);
      });
  }, [surveyFetchStatus, ids]);

  return (
    <Fragment>
      {isComplete(surveyFetchStatus) &&
        survey && [
          <LanguageSelector key="key-SelectLanguge">
            <Link href={`/self-assessment/en/${id}`}>en</Link>
            {" / "}
            <Link href={`/self-assessment/fi/${id}`}>fi</Link>
            {" / "}
            <Link href={`/self-assessment/se/${id}`}>se</Link>
          </LanguageSelector>,
          <SurveyIntro
            survey={survey}
            key="key-Intro"
            surveyStarted={surveyStarted}
            startSurvey={() => setSurveyStarted(true)}
          />,
          surveyStarted && (
            <Fragment>
              <SurveyContent
                content={survey.content.sections}
                key="key-Content"
              />
              <SubmitContainer>
                <SurveySubmit
                  postAnswers={postSelfAssessment}
                  key="key-Submit"
                />
              </SubmitContainer>
              <SurveyProgress
                key="key-Progress"
                postAnswers={postSelfAssessment}
              />
            </Fragment>
          )
        ]}
      {fetchError && <ErrorView error={fetchError} />}
      <TutorialPopup />
    </Fragment>
  );
}

export default SelfAssessment;
