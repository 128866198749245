import styled, { StyledComponent } from "styled-components";

export const IconContainer: StyledComponent<
  "span",
  { active: boolean },
  any
> = styled.span`
  height: 2rem;
  width: 2rem;
  opacity: ${(props: { active: boolean; answered: boolean }) =>
    props.active ? 1 : props.answered ? 0.05 : 0.4};

  > svg {
    height: 2rem;
  }
`;

export const ScaleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
