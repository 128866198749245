import styled from "styled-components";

export const Name = styled.h2`
  font-size: 2rem;
  margin: 1rem 0rem;
  padding: 0.5rem 1rem;
  display: inline;
  border-radius: 4px;
  font-family: "Red Hat Bold", sans-serif;
  background-color: var(--color-yellow-light);

  @media (max-width: 700px), (max-device-width: 700px) {
    font-size: 1.4rem;
  }
`;
