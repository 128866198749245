import { FunctionalComponent, h } from "preact";
import { Link } from "preact-router/match";
import styled from "styled-components";
import { TextBlock } from "../../core/style";

const Container = styled.div`
  flex: 1:
  display: flex;
  text-align: center;
  max-width: 600px;
`;

const Title = styled.h1`
  font-weight: 600;
  padding: 1rem;
  font-size: 2rem;
`;

const Failure: FunctionalComponent = () => {
  return (
    <Container>
      <Title>Something went wrong!</Title>
      <img
        src="/assets/images/bugs.svg"
        alt="Engineer driving bugs away from display"
      />
      <TextBlock>Good effort, but we messed up something.</TextBlock>
      <TextBlock>
        We should have received a notification about your issue. We'll
        investigate it as soon as possible.
        <Link href="https://app.thribune.com">
          {" "}
          Check out our website while you wait.
        </Link>
      </TextBlock>
    </Container>
  );
};

export default Failure;
