import { h, Fragment } from "preact";
import { useState } from "preact/hooks";
import SurveyIntro from "../../components/SurveyIntro/SurveyIntro";
import SurveyContent from "../../components/Sections/Sections";
import SurveyProgress from "../../components/SurveyProgress/SurveyProgress";
import { LanguageSelector } from "../../core/style";
import SurveySubmit from "../../components/SurveySubmit/SurveySubmit";
import { Link } from "preact-router";
import { Survey, AnsweredSurvey } from "../../models/Survey";
import styled from "styled-components";
import TutorialPopup from "../../components/TutorialPopup/TutorialPopup";

interface Props {
  survey: Survey;
  responseId: string;
  languages?: string[];
  postAnswers: (
    surveyId: string,
    responderId: string,
    answeredSurvey: AnsweredSurvey
  ) => Promise<unknown>;
}

const SubmitContainer = styled.div`
  margin: 4rem 0;
`;

const LangLink = styled(Link)`
  padding: 0.1rem 0.7rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  font-family: "Red Hat Bold";
  background-color: var(--color-yellow-light);
`;

function SurveyComponent({
  survey,
  responseId,
  postAnswers,
  languages = ["en", "fi", "se"]
}: Props) {
  const [surveyStarted, setSurveyStarted] = useState(false);

  return (
    <Fragment>
      <LanguageSelector key="key-SelectLanguge">
        {languages.length > 1 &&
          languages.map(language => (
            <LangLink key={language} href={`/survey/${language}/${responseId}`}>
              {language}
            </LangLink>
          ))}
      </LanguageSelector>
      <SurveyIntro
        survey={survey}
        key="key-Intro"
        surveyStarted={surveyStarted}
        startSurvey={() => setSurveyStarted(true)}
      />
      {surveyStarted && (
        <Fragment>
          <SurveyContent content={survey.content.sections} key="key-Content" />
          <SubmitContainer>
            <SurveySubmit postAnswers={postAnswers} key="key-Submit" />
          </SubmitContainer>
          <SurveyProgress postAnswers={postAnswers} key="key-Progress" />
        </Fragment>
      )}
      <TutorialPopup />
    </Fragment>
  );
}

export default SurveyComponent;
