import { Survey } from "../../../models/Survey";

export const RANGE_SCALE = [
  {
    label: {
      en: "Strongly disagree",
      fi: "",
      se: ""
    },
    value: 1
  },
  {
    label: {
      en: "Disagree",
      fi: "",
      se: ""
    },
    value: 2
  },
  {
    label: { en: "Neutral", fi: "Neutraali", se: "Neutral" },
    value: 3
  },
  {
    label: {
      en: "Agree",
      fi: "",
      se: ""
    },
    value: 4
  },
  {
    label: {
      en: "Strongly agree",
      fi: "",
      se: ""
    },
    value: 5
  }
];

export const FREE_SCALE = [
  {
    label: {
      en: "No scale",
      fi: "",
      se: ""
    },
    value: 1
  }
];

export const demoSurvey: Survey = {
  surveyId: "8cca635b-e3f8-4aa1-9e5f-4c13e2f4a4bc",
  createdAt: new Date(),
  surveyBaseId: "3bc3a5dd-0c6f-4a59-ace8-837cecb6c0ac",
  responderId: "f8fa499c-b286-467c-83fb-c51885425d79",
  content: {
    title: "Thribune Demo survey",
    sections: [
      {
        name: {
          en: "Thribune Happiness Index",
          fi: "Thribune Happiness Index",
          se: "Thribune Happiness Index"
        },
        title: {
          en: "How are you feeling at work.",
          fi: "",
          se: ""
        },
        questionSet: [
          {
            name: {
              en: "Usage of Thribune surveys",
              fi: "",
              se: ""
            },
            questions: [
              {
                id: "fake-1",
                question: {
                  en: "I've tried clicking on the icons.",
                  fi: "",
                  se: ""
                },
                type: "select",
                scale: RANGE_SCALE
              },
              {
                id: "fake-2",
                question: {
                  en: "I've tried using the sliding scale.",
                  fi: "",
                  se: ""
                },
                type: "select",
                scale: RANGE_SCALE
              }
            ],
            introduction: {
              en:
                "These questions relate to your usage of this demo tool. Try things out. Don't worry, we don't save your answers.",
              fi: "",
              se: ""
            }
          },
          {
            name: { en: "Commitment", fi: "Sitoutuminen", se: "Engagemang" },
            questions: [
              {
                id: "fake-3",
                question: {
                  en: "My work is important to me.",
                  fi: "",
                  se: ""
                },
                type: "select",
                scale: RANGE_SCALE
              },
              {
                id: "fake-4",
                question: {
                  en: "I feel a deep love for the work I do.",
                  fi: "",
                  se: ""
                },
                type: "select",
                scale: RANGE_SCALE
              }
            ],
            introduction: {
              en:
                "These questions relate to your commitment at work. To what extent do you agree with the following statements?",
              fi: "",
              se: ""
            }
          },
          {
            name: { en: "Enjoyment", fi: "Viihtyminen", se: "Trivsel" },
            questions: [
              {
                id: "fake-5",
                question: {
                  en: "In the morning I look forward to start the day at work.",
                  fi: "",
                  se: ""
                },
                type: "select",
                scale: RANGE_SCALE
              },
              {
                id: "fake-6",
                question: {
                  en: "The things that motivate me during workday are?",
                  fi: "",
                  se: ""
                },
                type: "freetext",
                scale: RANGE_SCALE
              },
              {
                id: "fake-7",
                question: {
                  en:
                    "Is there something that could be improved in the workplace?",
                  fi: "",
                  se: ""
                },
                type: "freetext",
                scale: FREE_SCALE
              }
            ],
            introduction: {
              en:
                "These questions relate to how much you enjoy your work. To what extent do you agree with the following statements?",
              fi: "",
              se: ""
            }
          }
        ],
        introduction: {
          en: "The Thribune Index is a peek at your general happiness today.",
          fi: "",
          se: ""
        }
      }
    ],
    introduction: {
      en:
        "Great to see you here! This is an example of a Thribune survey. While the content of the questions does not exactly match those of our platform, this should give you an idea of how our responding experience works.",
      fi: "",
      se: ""
    }
  }
};
