import { TextBlock } from "../../core/style";
import styled from "styled-components";
import { IconContainer } from "../Scale/style";

export const Overlay = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  display: flex;
  
  justify-content: center;
  overflow-y: scroll;
  align-items: flex-start;

  @media (min-width: 700px), (min-device-width: 700px) {
    align-items: center;
  }
}
`;

export const Modal = styled.div`
  border-radius: 6px;
  background-color: white;
  padding: 1rem 1.5rem;
  width: 100%;

  @media (min-width: 800px), (min-device-width: 800px) {
    width: 80%;
  }

  @media (min-width: 1000px), (min-device-width: 1000px) {
    width: 60%;
  }

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Header = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 3rem;
  font-family: "Red Hat Bold", sans-serif;

  @media (max-width: 700px), (max-device-width: 700px) {
    font-size: 2.5rem;
  }
`;

export const Content = styled.div`
  display: flex;
  @media (max-width: 700px), (max-device-width: 700px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 300px;
`;

export const FlexedText = styled(TextBlock)`
  display: flex;
`;

export const ExampleIcon = styled(IconContainer)`
  margin: 0rem 0.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: center;
`;
