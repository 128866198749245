import { FunctionalComponent, h } from "preact";
import styled from "styled-components";
import { TextBlock } from "../../core/style";

const Container = styled.div`
  flex: 1:
  display: flex;
  text-align: center;
`;

const Title = styled.h1`
  font-weight: 600;
  padding: 1.5rem;
  font-size: 2rem;
`;

const Notfound: FunctionalComponent = () => {
  return (
    <Container>
      <Title>There's nothing here</Title>
      <TextBlock>
        You might be trying to access your Thribune results?
      </TextBlock>
      <a href="https://app.thribune.com">
        <TextBlock>
          Sign into the portal with your Thribune account here.
        </TextBlock>
      </a>
      <TextBlock>Or find a link to the results from your email!</TextBlock>
    </Container>
  );
};

export default Notfound;
