import { h, Fragment } from "preact";
import { SurveySection } from "../../models/Survey";
import Section from "../Section/Section";

interface Props {
  content: SurveySection[];
}

const Sections = ({ content }: Props) => {
  return (
    <Fragment>
      {content.map((item, idx) => (
        <Section
          key={`section-${item.title.en}-${idx}`}
          section={item}
          prev={content[idx - 1]}
          next={content[idx + 1]}
        />
      ))}
    </Fragment>
  );
};

export default Sections;
