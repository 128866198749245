import styled, { AnyStyledComponent } from "styled-components";

export const Container: AnyStyledComponent = styled.section`
  padding: 3rem;
  height: ${(props: any) => (props.fullHeight ? "80vh" : "auto")};
  transition: 0.3s ease-in-out all;

  @media (max-width: 700px), (max-device-width: 700px) {
    padding: 2rem 1rem;
  }
`;

export const Title = styled.h1`
  padding: 1rem 0.5rem;
  font-size: 3rem;
  font-family: "Red Hat Bold", sans-serif;

  @media (max-width: 700px), (max-device-width: 700px) {
    font-size: 2.5rem;
  }
`;

export const Button = styled.button`
  border: none;
  color: var(--color-gray);
  font-family: "Red Hat Bold", sans-serif;
  box-shadow: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  font-size: 1.4rem;
  background: var(--color-yellow-light);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

  &:hover {
    cursor: pointer;
    background-color: var(--color-yellow-dark);
    color: var(--color-gray);
  }
`;

export const CenterVertical = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const CenterHorizontal = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;
