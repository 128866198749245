import { h } from "preact";
import { useState, useCallback, useEffect } from "preact/hooks";
import { IconContainer, ScaleContainer } from "./style";
import styled, { StyledComponent } from "styled-components";
import SvgIcon, { SvgOption } from "../Svg/SvgIcon";
import { useSurveyForm } from "../../state/navigation";
import { useDebounce } from "../../hooks/useDebounce";
import { useSurvey } from "../../state/root";
import { useScrollToQuestion } from "../../hooks/useScrollToQuestion";
import { SurveyQuestion } from "../../models/Survey";

const imageSelector = (idx: number, value: number, answered: boolean) => {
  const images: SvgOption[] = ["worst", "bad", "neutral", "good", "best"];
  return (
    <IconContainer
      answered={answered}
      active={
        value === null ||
        idx ===
          Math.max(Math.min(Math.floor(value - 1), images.length - 1), 0) ||
        idx === Math.max(Math.min(Math.ceil(value - 1), images.length - 1), 0)
      }
    >
      <SvgIcon option={images[idx]} />
    </IconContainer>
  );
};

const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: space-between;

  > span {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  height: 30px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  line-height: 0;
  padding: 1rem;
  cursor: pointer;
`;

const Range = styled.input`
  position: absolute;
  height: 50px;
  cursor: pointer;
  z-index: 1;
  left: 0;
  right: 0;
  width: 100%;
  top: -10px;
  opacity: 0;

  &::-webkit-slider-thumb {
    height: 30px;
    width: 30px;
    z-index: 20;
  }

  &::-moz-range-thumb {
    height: 30px;
    width: 30px;
    z-index: 20;
  }

  &::-ms-thumb {
    height: 30px;
    width: 30px;
    z-index: 20;
  }

  &:focus + span > span {
    background-color: var(--color-yellow-dark);
  }
`;

const SliderTrack = styled.span`
  height: 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  top: 10px;
  left: 0;
  position: absolute;
  border-radius: 5px;
`;

const SliderPin: StyledComponent<"span", { value: number }, any> = styled.span`
  top: -10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  left: calc(
    ${({ value }: { value: number }) => ((value - 1) / 4) * 100}% -
      ${({ value }: { value: number }) => ((value - 1) / 4) * 30}px
  );
  position: absolute;
  background-color: var(--color-yellow-light);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const ValueTrack: StyledComponent<"span", { value: number }, any> = styled.span`
  height: 10px;
  background-color: var(--color-yellow-light);
  left: 0;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  right: calc(
    100% - ${({ value }: { value: number }) => ((value - 1) / 4) * 100}%
  );
`;

interface Props {
  question: SurveyQuestion;
  isAnswered: boolean;
  onInteraction: (id: string, step: number) => void;
}

export const HorizontalScale = (props: Props) => {
  const { question, isAnswered, onInteraction } = props;
  const { id: questionId, scale } = question;
  const [value, setValue] = useState<number>(1.5);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);
  const [inputDragging, setInputDragging] = useState<boolean>(false);
  const { setAnswer } = useSurvey();
  const { setActiveQuestion, activeQuestion, unfocus } = useSurveyForm();
  const debouncedValue = useDebounce(value, isAnswered ? 400 : 800);
  const debouncedForceRefresh = useDebounce(forceRefresh, 400);
  const debouncedInputDragging = useDebounce(inputDragging, 400);
  const inputRef = useScrollToQuestion(questionId);

  useEffect(() => {
    if (hasInitialized && !inputDragging) {
      onInteraction(questionId, isAnswered ? 0 : 1);
      setAnswer(questionId, value);
    }
  }, [debouncedValue, debouncedForceRefresh, debouncedInputDragging]);

  const changeInputValue = useCallback(
    (newValue: number) => {
      setHasInitialized(true);
      setValue(newValue);
    },
    [setHasInitialized, setValue]
  );

  const onChange = useCallback(
    ({ target }: { target: HTMLInputElement }) => {
      const parsed = parseFloat(target.value);
      changeInputValue(parsed);
    },
    [setHasInitialized, setValue]
  );

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      return unfocus();
    }

    const nextKeys = ["ArrowUp", "ArrowDown", "Enter"];
    if (nextKeys.includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      if (e.key === "ArrowUp") {
        return onInteraction(questionId, -1);
      }

      if (e.key === "Enter") {
        setHasInitialized(true);
        return setForceRefresh(true);
      }

      return onInteraction(questionId, 1);
    }
  };

  const handleIconPress = (idx: number) => {
    if (!forceRefresh) {
      setForceRefresh(true);
    }

    changeInputValue(idx + 1);
  };

  return (
    <ScaleContainer>
      <RangeContainer>
        {scale.map((item, idx) => (
          <span
            role="button"
            onClick={() => handleIconPress(idx)}
            key={`smiley-icon-${questionId}-${idx}`}
          >
            {imageSelector(idx, value, isAnswered)}
          </span>
        ))}
      </RangeContainer>
      <SliderContainer>
        <Range
          onKeyDown={handleKeyDown} // TODO: uncomment this
          /**onFocus={() => setActiveQuestion(questionId!)}**/
          onBlur={() => unfocus()}
          ref={activeQuestion === questionId ? inputRef : null}
          type="range"
          min="1"
          max="5"
          step="0.5"
          value={value}
          onInput={onChange}
          onMouseDown={() => setInputDragging(true)}
          onMouseUp={() => setInputDragging(false)}
          onTouchStart={() => setInputDragging(true)}
          onTouchEnd={() => setInputDragging(false)}
        />
        <SliderTrack>
          <ValueTrack value={value} />
          <SliderPin value={value} />
        </SliderTrack>
      </SliderContainer>
    </ScaleContainer>
  );
};

export default HorizontalScale;
