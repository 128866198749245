import styled, { AnyStyledComponent } from "styled-components";

export const CardQuestion: AnyStyledComponent = styled.div`
  margin: 1rem 0rem;
  margin-bottom: 2rem;
  border-radius: 6px;
`;

export const UserAnswer = styled.div`
  flex-direction: row;
  padding: 1rem 0.5rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: ${(props: any) => (props.checked ? "flex" : "none")};
`;

export const QuestionText = styled.p`
  flex: 2;
  font-size: 1.3rem;
  font-family: "Red Hat Display", sans-serif;
  line-height: 1.5;

  > mark {
    background-color: ${(props: any) =>
      props.active ? "var(--color-yellow-light)" : "transparent"};
    white-space: post-wrap;
    border-radius: 2px;
  }
`;

export const CardHeader: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CardContent = styled.div``;

export const AnswerValue = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: var(--color-yellow-light);
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 1.1rem;
  font-family: "Red Hat bold", sans-serif;

  > svg {
    height: 1.5rem;
    padding-right: 0.5rem;
  }
`;

export const AnswerLabel = styled.span`
  padding: 0.5rem;
  font-size: 1.2rem;
  font-family: "Zilla Slab Medium", serif;
`;

export const InfoText = styled.span`
  color: #2c5282;
  font-family: "Zilla Slab Medium", serif;
  padding: 0rem 0.5rem;
`;
