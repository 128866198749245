import styled, { AnyStyledComponent } from "styled-components";

export const QuestionContainer: AnyStyledComponent = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const CardQuestion: AnyStyledComponent = styled.div`
  margin: 1rem 0rem;
  border-radius: 6px;
  opacity: ${(props: any) => (props.active ? 1 : 0.5)};
`;

export const CheckMark = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > span {
    padding: 0 1rem;
    font-weight: 600;
    font-size: 0.9rem;
  }

  svg {
    height: 1.5rem;
    opacity: ${(props: any) => (props.checked ? 1 : 0.2)};
  }
`;

export const QuestionText = styled.h4`
  flex: 2;
  font-size: 1.3rem;
  font-family: "Red Hat Light", sans-serif;
  line-height: 1.5;

  > mark {
    background-color: ${(props: any) =>
      props.active ? "var(--color-yellow-light)" : "transparent"};
    white-space: post-wrap;
    border-radius: 2px;
  }
`;

export const CardHeader: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 0.5rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CardContent = styled.div``;

export const SetLabel = styled.h3`
  color: var(--color-gray);
  margin-top: 1.5rem;
  font-size: 1.6rem;
  padding: 1rem 0.5rem;
  font-family: "Red Hat Bold", sans-serif;
`;
