import { h } from "preact";
import { format } from "date-fns";

import { Survey } from "../../models/Survey";
import {
  Container,
  Title,
  CenterVertical,
  CenterHorizontal,
  Button
} from "./SurveyIntro.style";
import { useSurvey, RootState } from "../../state/root";
import { translate } from "../../utils/language";
import { TextBlock } from "../../core/style";
import { useCallback } from "preact/hooks";
import { useSurveyForm } from "../../state/navigation";

const getLanguage = (root: RootState) => root.state.language;

const buttonText = {
  en: "Let's begin",
  fi: "Aloita kysely",
  se: "Låt oss börja"
};

interface Props {
  survey: Survey;
  surveyStarted: boolean;
  startSurvey: () => void;
}

const SurveyIntro = ({ survey, surveyStarted, startSurvey }: Props) => {
  const { content, createdAt } = survey;
  const { title, introduction } = content;
  const lang = useSurvey(getLanguage);
  const { setActiveQuestion } = useSurveyForm();
  const firstQuestionId = content.sections[0].questionSet[0].questions[0].id;

  const handleSurveyStart = useCallback(() => {
    startSurvey();
    setActiveQuestion(firstQuestionId);
  }, [firstQuestionId, setActiveQuestion, startSurvey]);

  return (
    <Container fullHeight={!surveyStarted}>
      <CenterVertical>
        <Title>
          {title} - {createdAt && format(new Date(createdAt), "d.M.yyyy")}
        </Title>
        <TextBlock>{translate(introduction, lang)}</TextBlock>
        <CenterHorizontal style={{ marginTop: "2rem" }}>
          {!surveyStarted && (
            <Button onClick={handleSurveyStart}>
              {translate(buttonText, lang)}
            </Button>
          )}
        </CenterHorizontal>
      </CenterVertical>
    </Container>
  );
};

export default SurveyIntro;
