import { FunctionalComponent, h } from "preact";
import { Route, Router } from "preact-router";
import SurveyContainer from "../routes/survey/SurveyContainer";
import SelfAssessment from "../routes/survey/SelfAssessment";
import Notfound from "../routes/notfound/NotFound";
import { Application } from "../core/style";
import styled from "styled-components";
import Success from "../routes/success/Success";
import Failure from "../routes/failure/Failure";
import DemoPage from "../routes/survey/demo/Demo";

const Header = styled.header`
  img {
    max-width: min(200px, 30vw);
    min-width: 100px;
  }
`;

const App: FunctionalComponent = () => {
  return (
    <Application>
      <Header>
        <img width="300" src="/assets/images/logo.png" alt="Thribune Logo" />
      </Header>
      <Router>
        <Route
          path="/self-assessment/:language/:id"
          component={SelfAssessment}
        />
        <Route path="/survey/:language/:id" component={SurveyContainer} />
        <Route path="/success" component={Success} />
        <Route path="/oh-dmn" component={Failure} />
        <Route path="/demo" component={DemoPage} />

        <Route default component={Notfound} />
      </Router>
    </Application>
  );
};

export default App;
