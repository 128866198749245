import { h } from "preact";
import SurveyComponent from "../Survey";
import { demoSurvey } from "./content";
import { useSurvey } from "../../../state/root";
import { useEffect } from "preact/hooks";

const DemoPage = () => {
  const { updateSlice } = useSurvey();

  useEffect(() => {
    updateSlice({ language: "en", survey: demoSurvey });
  }, []);

  return (
    <SurveyComponent
      survey={demoSurvey}
      responseId="demo"
      languages={["en"]}
      postAnswers={() => Promise.resolve()}
    />
  );
};

export default DemoPage;
